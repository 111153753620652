/**
 * Pre footer uniformify
 */

$(function () {

    var wrap = $('.pre-footer-section > .wrap');

    wrap.uniformify({
        selector: '.cell *',
    });

});