$(function () {

    /**
     * Add/remove classes to header and nav elements
     * when JS is enabled.
     */

    $('.site-nav').addClass('hidden');
    $('.nav-toggle').addClass('visible -important');
    $('.col-1').addClass('-flex');
    $('.site-header').addClass('-fixed');
    $('.site-container').addClass('-js-site-container');

});

$(function () {

    /**
     * Nav toggle functionality
     */

    var nav = $('.site-nav');
    var button = $('.nav-toggle');
    var navOff = true;

    button.on('click', function () {

        if (navOff) {

            navOff = false;
            nav.removeClass('hidden');

        } else {

            navOff = true;
            nav.addClass('hidden');

        }

    });

});