$(function () {

    lightbox.option({

        'disableScrolling': true,
        'resizeDuration': 500,
        'alwaysShowNavOnTouchDevices': true,
        'wrapAround': true,

    });

});