$(function () {
    /**
     * Re-render facebook widget iframe on window resize
     */
    var TIMEOUT = null;

    $(window).on('resize', function () {
        if (TIMEOUT === null) {
            TIMEOUT = window.setTimeout(function () {
                TIMEOUT = null;
                //fb_iframe_widget class is added after first FB.FXBML.parse()
                //fb_iframe_widget_fluid is added in same situation, but only for mobile devices (tablets, phones)
                //By removing those classes FB.XFBML.parse() will reset the plugin widths.
                $('.fb-page').removeClass('fb_iframe_widget fb_iframe_widget_fluid');

                FB.XFBML.parse();
            }, 300);
        }
    });
});