$(function () {

    /**
     * Add/remove classes when JS is enabled
     */
    $('.subnav').addClass('hidden');

});

$(function () {

    /**
     * Show/hide subnav
     */

    var arrow = $('.site-nav ul li .arrow');

    arrow.each(function () {

        var subnav = $(this).next('.subnav');

        $(this).on('click', function () {

            $(this).toggleClass('-active-arrow');
            subnav.toggleClass('visible -important');
            $(this).sibling('.-blue-subnav').addClass('-subnav-blue');
            $(this).sibling('.-red-subnav').addClass('-subnav-red');

        });

    });


    /**
     * Remove classes on window resize
     */

    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    $(window).on('resize', function () {

        if ($(this).width() != width) {

            width = $(this).width();

            if (width <= 960) {

                arrow.removeClass('-active-arrow');
                arrow.next('ul').removeClass('visible -important');
                $(this).next('-blue-subnav').removeClass('-subnav-blue');
                $(this).next('-red-subnav').removeClass('-subnav-red');

            }

        }

    });


});